<template>
  <div class="view-wrap">
    <el-form :model="searcForm" class="F">
      <el-form-item label="仓库:" style="width: 330px">
        <el-select v-model="searcForm.wmsIds" multiple placeholder="">
          <el-option
            v-for="item in storehouse"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="商品:"
        prop="productId"
        style="width: 330px"
        class="F"
      >
        <el-input
          v-if="checkProductName === ''"
          placeholder="请选择商品"
          clearable
          v-model="checkProductName"
          @focus.stop="openProduct"
          @clear="clearProduct"
        ></el-input>
        <el-tooltip
          v-else
          class="item"
          effect="dark"
          :content="checkProductName"
          placement="top-start"
        >
          <el-input
            placeholder="请选择商品"
            clearable
            v-model="checkProductName"
            @focus.stop="openProduct"
            @clear="clearProduct"
          ></el-input>
        </el-tooltip>
      </el-form-item>

      <el-form-item>
        <div class="F">
          <el-button type="primary" size="" @click="searchForm">查询</el-button>
          <el-button type="primary" size="" @click="handleDownload" plain
            >导出</el-button
          >
        </div>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%" border="" id="wl">
      <el-table-column prop="code" label="商品编号" align="center">
      </el-table-column>
      <el-table-column prop="name" label="商品名称" align="center">
      </el-table-column>
      <el-table-column prop="barCode" label="条码" align="center">
        <template slot-scope="scope">
          {{ scope.row.barCode ? scope.row.barCode : "--" }}
        </template>
      </el-table-column>
      <el-table-column prop="spec" label="规格" align="center">
        <template slot-scope="scope">
          {{ scope.row.spec ? scope.row.spec : "--" }}
        </template>
      </el-table-column>
      <el-table-column prop="unit" label="基础单位" align="center">
      </el-table-column>
      <div v-for="(item, index) in wmsLists" :key="index">
        <el-table-column :label="item.wmsName" align="center">
          <el-table-column prop="num" label="数量" align="center">
            <template slot-scope="scope">
              <span>
                {{
                  getValue("num", item.goodsArr, scope.row.code) || "--"
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="totalPrice" label="总成本" align="center">
            <template slot-scope="scope">
              <span>
                {{
                  Number(
                    getValue("totalPrice", item.goodsArr, scope.row.code)
                  ).toFixed(2) || "--"
                }}</span
              >
            </template>
          </el-table-column>
        </el-table-column>
      </div>

      <el-table-column label="合计" align="center">
        <el-table-column prop="totalNum" label="数量" align="center">
        </el-table-column>
        <el-table-column prop="totalPrices" label="总成本" align="center">
          <template slot-scope="scope">
            {{
              scope.row.totalPrices ? scope.row.totalPrices.toFixed(2) : "--"
            }}
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <miaDialog width="70%" ref="SelectGoods" v-if="Visible">
      <div slot="content">
        <SelectGoods
          :isStatement="true"
          :checkArr="checkProductArr"
          @close="Visible = false"
          @SelectGoods="changeProductId"
        />
      </div>
    </miaDialog>
    <OpenAccount :info="info"></OpenAccount>
  </div>
</template>

<script>
import SelectGoods from "@/components/pustorageTable/SelectGoods.vue";
import { getProductList, getTheWarehouseList } from "@/api/procurementSummary";
import { getDistribution } from "@/api/inventoryTable";
import { getConfigBill } from "@/api/user";
import OpenAccount from "@/views/common/OpenAccount";
export default {
  components: {
    SelectGoods,
    OpenAccount,
  },
  data() {
    return {
      latter: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      multiHeader: [
        ["商品编号", "商品名称", "条码", "规格", "基础单位", "合计", ""],
      ],
      header: ["", "", "", "", "", "数量", "总成本"],
      filterVal: [
        "code",
        "name",
        "barCode",
        "spec",
        "unit",
        "totalNum",
        "totalPrices",
      ],
      merges: ["A1:A2", "B1:B2", "C1:C2", "D1:D2", "E1:E2"],
      info: {
        show: false,
      },
      storehouse: [], //仓库列表
      productList: [], //商品列表
      tableData: [], //表格数据
      searcForm: {
        productIds: "",
        wmsIds: "",
      }, //搜索条件
      Visible: false,
      checkProductArr: [],
      checkProductName: "",
      wmsLists: [],
    };
  },
  created() {
    this.getConfigBills();
    this.getProductList();
    this.getTheWarehouseList();
    this.getList();
  },
  methods: {
    // 导出单个表头
    handleDownload() {
      //处理第一层表头
      let multiHeader2 = [];
      let arr1 = this.wmsLists.map((item) => item.wmsName);
      let multiHeader1 = this.multiHeader[0].slice(0, 5);
      let multiHeader3 = this.multiHeader[0].slice(-2);
      for (let index = 0; index < arr1.length; index++) {
        multiHeader2.push(arr1[index], "");
      }
      let arr2 = multiHeader1.concat(multiHeader2);
      let arr3 = arr2.concat(multiHeader3);
      let arr4 = [];
      arr4.push(arr3);
      // console.log(arr4);
      this.multiHeader = arr4;
      //处理第二层表头
      let header1 = this.header.slice(0, 5);
      let header3 = this.header.slice(5, 7);
      let header2 = [];
      for (let index = 0; index < arr1.length; index++) {
        header2.push("数量", "总成本");
      }
      let head1 = header1.concat(header2);
      this.header = head1.concat(header3);
      //处理合并单元格
      let merges1 = [];
      for (let index = 5; index < arr1.length + 10; ) {
        merges1.push(this.latter[index] + 1 + ":" + this.latter[index + 1] + 1);
        index += 2;
      }
      let merges2 = [];
      merges2 = this.merges.concat(merges1);
      this.merges = merges2;
      let array = this.tableData.map((item) => item.wmsList);
      console.log(array);
      let obj = {
        num: "",
        totalPrice: "",
        productId: "",
      };
      let array2 = [];
      array.forEach((item) => {
        item.forEach((items) => {
          obj = { ...items };
          array2.push(obj);
        });
      });
      let array3 = [];
      array2.forEach((item) => {
        array3.push(item.num);
        array3.push(item.totalPrice);
      });
      let array4 = this.sliceArrFn(array3);
      let array5 = this.formatJson(this.filterVal, this.tableData);
      let a1 = [];
      let a3 = [];
      array5.forEach((item) => {
        a1.push(item.slice(0, 5));
        a3.push(item.slice(-2));
      });
      let a4 = [];
      a1.forEach((item, index) => {
        array4.forEach((items, indexs) => {
          if (index == indexs) {
            a4.push(item.concat(items));
          }
        });
      });
      let a5 = [];
      a4.forEach((item, index) => {
        a3.forEach((items, indexs) => {
          if (index == indexs) {
            a5.push(item.concat(items));
          }
        });
      });
      import("@/until/Export2Excel").then((excel) => {
        const multiHeader = this.multiHeader; // 前两行的表头数据，二维数组，不够的用空白补全
        const header = this.header; // 最后一行的表头数据
        // const filterVal = this.filterVal;
        // 这里要注意 header  和 filterVal 的数组长度一定要相等，否则会报错
        // const list = this.tableData;
        const data = a5;
        const merges = this.merges; // 合并单元格的数据，如何合并参考上面图片讲解
        excel.export_json_to_excel({
          multiHeader,
          header,
          merges,
          data,
          filename: "库存分布表",
        });
      });
    },
    // 拼接数据
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    sliceArrFn(array) {
      let result = [];
      let size = this.wmsLists.length * 2; //自定义数组的长度
      for (let x = 0; x < Math.ceil(array.length / size); x++) {
        let start = x * size;
        let end = start + size;
        result.push(array.slice(start, end));
      }
      return result;
    },
    async getConfigBills() {
      let res = await getConfigBill();
      if (res.code == 1) {
        if (res.data.isBill == 0) {
          this.info.show = true;
        }
      }
    },
    clearProduct() {
      this.checkProductArr = [];
    },
    checkProductArrs(val) {
      this.checkProductName =
        val.length === 0
          ? ""
          : val
              .map((val) => {
                return val.name;
              })
              .join("、");
      this.searcForm.productIds =
        val.length === 0
          ? ""
          : val
              .map((val) => {
                return val.id;
              })
              .join(",");
    },
    openProduct() {
      this.Visible = true;
      this.$nextTick(() => {
        this.$refs.SelectGoods.init("选择商品");
      });
    },
    changeProductId(list) {
      this.Visible = false;
      this.checkProductArr = list;
      this.checkProductArrs(list);
    },
    async getList() {
      let params = {
        productIds: this.searcForm.productIds || this.$route.query.id,
        wmsIds: this.searcForm.wmsIds,
      };
      let res = await getDistribution(params);
      if (res.code == 1) {
        this.tableData = res.data;
        this.getVales(res.data);
      }
    },
    getValue(key, arr, val) {
      let reVal = "";
      arr.forEach((item) => {
        if (val == item.code) {
          reVal = item[key];
        }
      });
      return reVal;
    },
    //处理仓库数据
    getVales(arr) {
      let arr2 = [];
      let arr3 = []; //单独统计仓库
      arr.forEach((a) => {
        a.wmsList.forEach((b) => {
          arr2.push({ ...b, code: a.code }); //放入商品唯一值
        });
      }); // 去重仓库,统计仓库个数
      let newobj = {};
      arr3 = arr2.reduce((preVal, curVal) => {
        newobj[curVal.wmsId]
          ? ""
          : (newobj[curVal.wmsId] = preVal.push(curVal));
        return preVal;
      }, []); // 根据去重后仓库进行数据合并

      let arr4 = []; //最终数据
      arr3.forEach((v) => {
        let goodsArr = []; //同仓库下的商品集合
        arr2.forEach((b) => {
          if (v.wmsId == b.wmsId) {
            goodsArr.push(b);
          }
        });
        arr4.push({ goodsArr: goodsArr, ...v });
      });
      this.wmsLists = arr4;
      console.log(arr4);

      this.tableData.forEach((item) => {
        item.wmsList.forEach((items, indexs) => {
          let totalNum = 0;
          let totalPrices = 0;
          if (items.num > 0) {
            if (item.id === items.productId) {
              totalNum += items.num;
              totalPrices += items.totalPrice;
              // console.log(items.totalPrice, items.num);
              if (indexs != 0) {
                totalNum += item.totalNum;
                totalPrices += item.totalPrices;
              }

              item.totalNum = totalNum;
              item.totalPrices = totalPrices;
            }
          }
        });
      });
      console.log(this.tableData);
    },
    //查询按钮
    searchForm() {
      this.getList();
    },
    //获取仓库列表
    async getTheWarehouseList() {
      let res = await getTheWarehouseList();
      if (res.code == 1) {
        this.storehouse = res.data.records;
      }
    },
    //获取商品列表
    async getProductList() {
      let res = await getProductList();
      if (res.code == 1) {
        this.productList = res.data.records;
      }
    },
  },
};
</script>

<style scoped>
.F {
  display: flex;
  flex-wrap: wrap;
}
.export-btn {
  width: 60px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #409eff;
  text-align: center;
  color: #409eff;
  font-size: 12px !important;
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer;
}
.view-wrap >>> th .cell {
  font-weight: bold !important;
  color: #606266 !important;
}
.view-wrap >>> .el-select__tags {
  position: absolute;
  line-height: normal;
  white-space: normal;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.view-wrap >>> .el-select__tags {
  overflow: hidden !important;
}
.view-wrap >>> .el-table__footer-wrapper tbody td {
  color: #000;
  font-weight: bold;
}
</style>